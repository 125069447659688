:root {
    --primary-color: #00aeef;
    --black-color: #000000; 
    --global-padding: 20px;
}

html {
scroll-behavior: smooth;
}

body.popup-active {
    overflow: hidden;
}

p {
    line-height: 1.5;
}

.section-about p {
    line-height: 2;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
}

h1 {
    font-size: 36px;
}

h1 span,
h1 small {
    display: block;
    color: rgba(0, 0, 0, 0.6);
}

h1 span {
    font-size: 26px;
}

h1 small {
    font-size: 16px;
    letter-spacing: 3px;
}

.fullwidth {
    width: 100%;
}

.user-holder img {
    display: block;
}

.clip-path-1 {
    clip-path: polygon(100% 0%, 100% 98.5%, 0% 100%, 0% 0%, 50% 1.5%);
    animation: clips 3s ease infinite;
    transition: 1s;
}


.section-shadow-top {
    box-shadow: 0 -70px 60px 30px rgba(255, 255, 255, 1);
    animation: shadow 3s ease infinite;
}

@keyframes shadow {
    50% {
        box-shadow: 0 -40px 60px 30px rgba(255, 255, 255, 1);
    }
}

.relative {
    position: relative;
}

.relative-1 {
    position: relative;
    z-index: 5;
}

.section-welcome h1 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 26px;
    color: var(--primary-color);
}

.typewriter {
    display: inline-block;
}

.typewriter span {
    font-size: 20px;
}

/* The typing effect */
@keyframes typing {
    0% { width: 0 }
    15% { width: 100% }
    100% { width: 100% }
}
  
/* The typewriter cursor effect */
@keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: (var(--black-color)) }
}  

@keyframes color-blink {
    0% {
        color: rgba(0, 0, 0, 0.6);
    }

    70% {
        color: rgba(0, 0, 0, 0.6);
    }

    100% {
        color: var(--black-color);
    }
}

.section-padding {
    padding-top: 70px;
    padding-bottom: 70px;
}

.box-inner {
    max-width: 768px;
    margin: 0 auto;
}

blockquote {
    margin-bottom: 26px;
    font-size: 18px;
    color: var(--primary-color);
    font-weight: 300;
}

blockquote strong {
    font-weight: 500;
}

blockquote small {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 300;
}

.social-links {
    margin-top: 30px;
    margin-bottom: 30px;
}

.social-links a {
    display: inline-block;
    border: 2px solid rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 6px;
    padding: 6px;
    font-size: 22px;
  }

  .social-links a:hover {
      border-color: var(--primary-color);
  }

  .social-links .facebook svg {
      fill: #5F83CF;
  }
  
  .social-links .instagram svg {
      fill: #973EB4;
  }
  
  .social-links .linkedin svg {
      fill: #0274B3;
  }

  .social-links .youtube svg {
      fill: #FF0000;
  }

  .social-links .github svg {
    fill: #323131;
}

  .social-links .email svg {
      fill: #27ADE4;
  }

  .social-links .skype svg {
      fill: #01AEF2;
  }

  .social-links .mobile svg {
      fill: #7FCE63;
  }

  .social-links .contact svg {
      fill: #4AA879;
  }

  .title-tag {
    display: inline-block;
    background-color: var(--primary-color);
    padding: 20px 30px;
    margin-top: 20px;
    margin-bottom: 40px;
    font-size: 30px;
    font-weight: 300;
    color: white;
  }

  .title-tag::before {
    display: inline-block;
    padding-right: 20px;
    margin-top: 2px;
    color: white;
    font-size: 24px;
  }

  .title-tag small {
      color: white;
  }

  .profile-cart {
      background-color: white;
      padding: 40px 30px;
      margin-bottom: 30px;
  }

  .profile-cart h2 {
      font-size: 24px;
      margin-bottom: 20px;
  }

  .profile-cart__tag {
    position: absolute;
    top: 5px;
    left: 5px;
    display: inline-block;
    max-width: 90%;
    text-align: left;
  }

  .profile-cart__tag span {
    position: relative;
    display: inline-block;
    background-color: var(--primary-color);
    padding: 4px 10px;
    color: white;
    font-size: 13px;
  }

  .profile-cart__tag::before {
    content: "";
    position: absolute;
    top: -1px;
    left: 15px;
    display: inline-block;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+1,000000+100&1+0,0+100 */
    background: -moz-linear-gradient(left,  rgba(0,0,0,1) 0%, rgba(0,0,0,0.99) 1%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  rgba(0,0,0,1) 0%,rgba(0,0,0,0.99) 1%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  rgba(0,0,0,1) 0%,rgba(0,0,0,0.99) 1%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */
    clip-path: polygon(100% 100%, 85% 0%, 100% 0%, 100% 0%, 0% 15%, 0% 75%, 85% 95%);
    width: 100%;
    height: calc(100% + 8px);

  }

  .portfolio-cart {
    margin-bottom: 30px;
  }

  .portfolio-cart a {
      display: block;
      text-decoration: none;
      color: inherit;
  }

  .portfolio-cart figure {
    background-color: white;
    border: 10px solid rgba(0, 0, 0, 0.1);
    padding: 10px;
  }

  .portfolio-cart img {
      transform-origin: top left;
      transition: 0.3s;
  }

  .portfolio-cart figure a {
      overflow: hidden;
  }

  .portfolio-cart figure.figure:hover img {
    transform: initial;
  }

  .portfolio-cart h3 {
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 20px;
    padding: 10px;
  }  

  .portfolio-cart h3 a:hover {
      color: var(--primary-color);
  }

  .portfolio-cart figure:hover .portfolio-cart__options {
      width: 100%;
  }

  .portfolio-cart__options {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.5);
      overflow: hidden;
      transition:0.3s;
  }

  .portfolio-cart__options a {
    display: inline-block;
    border: 1px solid var(--primary-color);
    padding: 10px;
    margin: 0 15px;
    font-size: 22px;
    color: rgba(255, 255, 255, 0.9);
  }

  .portfolio-cart__options a:hover {
    transform: scale(1.5);
    color: var(--primary-color);
  }

  .preview {
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.7);
      width: 100%;
      height: 0;  
      overflow-y: scroll;
      z-index: 1000;
      transition: 0.3s;
  }

  .preview--active {
    height: 100%;
  }

  .preview__links {
      padding: 20px;
      text-align: center;
  }

  .preview__links a {
    font-size: 24px;
  }

  .preview__links a:hover {
      color: var(--primary-color);
  }

  .preview__inner {
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    max-width: 1000px;
    padding: 50px 20px;
    margin: 0 auto;
    text-align: left;
  }

  .preview__job ul {
      margin-left: 16px;
  }

  .preview__close {
      position: absolute;
      top: 15px;
      right: 20px;
  }

  .preview__close {
      font-size: 24px;
      cursor: pointer;
      color: red;
  }

  .preview h3 {
      border: 0;
      padding: 0;
      margin-bottom: 20px;
      font-size: 32px;
  }

  .preview h4 {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 600;
  }

  .preview h5 {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 500;
      text-decoration: underline;
  }

  .contact-detail ul {
      display: inline-block;
      background-color: rgba(255, 255, 255, 0.9);
      margin: 0;
      padding: 20px;
      list-style: none;
  }

  .contact-detail li {
      font-size: 13px;
  }

  .contact-detail a {
      color: var(--primary-color);
      text-decoration: none;
      transform-origin: top left;
  }

  .contact-detail a:hover {
      text-decoration: underline;
      transform: scale(1.1);
      color: var(--black-color);
  }

  .resume-pdf a {
      text-decoration: none;
      color: red;
      font-size: 22px;
  }

  .resume-pdf a strong {
      color: var(--black-color);
  }

  .resume-pdf a:hover {
      transform: scale(1.2);
  }

  .section-welcome .box {
        padding-top: 50px;
    }   

  @media all and (min-width: 320px) {
    .typewriter span {
        border-right: 1px solid orange; 
        white-space: nowrap;
        letter-spacing: .15em;
        overflow: hidden;
        animation: typing 7s steps(24, end) infinite, blink-caret 0.5s step-end infinite, color-blink 1s infinite;
    }
  }

@media all and (min-width: 480px) {
    .section-welcome h1 {
        font-size: 36px;
    }

    .typewriter span {
        font-size: 26px;
    }
}

@media all and (min-width: 640px) {
    .preview__inner {
        flex-wrap: initial;
    }

    .preview__image {
        margin-right: 20px;  
        max-width: 40%;
    }
}

@media all and (min-width: 840px) {
    .section-welcome .box {
        padding-top: 170px;
    }

    blockquote {
        font-size: 30px;
    }

    blockquote small {
        font-size: 20px;
    }

    .contact-detail ul {
        padding: 80px;
    }

    .contact-detail li {
        font-size: 16px;
    }
}

@media all and (min-width: 1024px) {
    .section-welcome h1 {
        padding: var(--global-padding);
    }

    .clip-path-1 {
        clip-path: polygon(100% 0%, 100% 95%, 0% 100%, 0% 0%, 50% 4%);
    }
}

@media all and (min-width: 1200px) {
    .portfolio-cart__options {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
    }

    .portfolio-cart figure:hover img,
    .portfolio-cart figure a:hover img {
        transform: scale(1.02);
    }

    .preview__inner {
        padding: 50px;
    }
}