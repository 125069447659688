:root {
    --primary-color: #00aeef;
    --black-color: #000000; 
    --global-padding: 20px;
}

body {
    font-size: 15px;
    font-weight: normal;
    line-height: 1.3;
}

/**
* Header sticky
*/
header.fixed-top {
    position: fixed;
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    margin-top: 0;
    padding-top: 10px;
}

header.fixed-top .site-logo a {
    padding-top: 0;
    padding-bottom: 0;
}

header.fixed-top #menu li a {
    padding-top: 5px;
    padding-bottom: 5px;
}
/**
* endHeader Sticky
*/
header {
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-top: 5px;
    padding-bottom: 5px;
    z-index: 10;
    transition: 0.5s;
}

header .col-holder {
    align-items: center;
}

.site-logo a {
    display: inline-block;
    max-width: 40px;
    padding: 10px 0;
}

nav {
    position: relative;
    text-align: right;
}

.toggle-nav {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 20px;
    vertical-align: middle;
}

.toggle-nav::before,
.toggle-nav::after {
    content: "";
}

.toggle-nav::before,
.toggle-nav::after,
.toggle-nav span {
    position: absolute;
    transform: translate(0, -50%);
    display: block;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 2px;
    transition: 0.6s;
}

.toggle-nav::before {
    top: 0;
}

.toggle-nav::after {
    top: 100%;
}

.toggle-nav span {
    top: 50%;
}

.toggle-nav.active::before,
.toggle-nav.active::after,
.toggle-nav.active span {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    height: 2px;
}

.toggle-nav.active span {
    opacity: 0;
}

.toggle-nav.active.animate::before {
    transform: translate(0, -50%) rotate(45deg);
}

.toggle-nav.active.animate::after {
    transform: translate(0, -50%) rotate(-45deg);
}

#menu {
    position: absolute;
    top: 40px;
    right: 0;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 0;
    max-width: 250px;
    text-align: left;
    overflow: hidden;
    transition: 1s;
    z-index: 1;
}

#menu.active {
    width: 100%;
}

#menu li {
    display: block;
    border-bottom: 1px solid transparent;
    padding: 0;
}

#menu li a {
    border: 1px solid transparent;
    width: 100%;
    padding: 10px 20px;
    text-decoration: none;
    color: var(--black-color);
    text-transform: uppercase;
    font-size: 0;
    transition: 0.3s;
}

#menu li.show {
    border-bottom-color: rgba(0, 0, 0, 0.1);
    transition: 0.5s;
}

#menu li.show a {
    font-size: 14px;    
}

#menu li.active a,
#menu li.current-menu-item a,
#menu li a:hover {
    border-top-color: var(--primary-color);
}

@media all and (min-width: 768px) {
    header {
        margin-top: 20px;
    }
    
    header .col-holder {
        align-items: initial;
    }

    .site-logo a {
        max-width: 70px;
        padding: 10px;
    }

    .toggle-nav {
        display: none;
    }

    #menu {
        position: static;
        background-color: transparent;
        border: 0;
        width: 100%;
        max-width: none;
        text-align: right;
    }

    #menu li {
        display: inline-block;
        border-bottom: 0;
        padding: 0 var(--global-padding);
    }

    #menu li.show {
        border-bottom: 0;
    }

    #menu li a {
        padding: var(--global-padding) 0;
        font-size: 14px;
    }
}

#footer a {
    color: #222;
}